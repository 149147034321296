import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
import { vars } from "../../../css/vars.css";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <>
          <img src="https://i.ibb.co/QnJRqNc/billicat.png" {...props} alt="" />
    </>
  );
};

export default Logo;

import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Billidex',
  defaultTitle: 'Billidex',
  description:
    'Billi-Dex, a revolutionary cryptocurrency DEX, beckons you into a world where seamless transactions meet cutting-edge technology. With its sleek interface and lightning-fast execution, Billi-Dex transforms swapping digital assets into a delightful experience, empowering users to navigate the crypto space with ease. Welcome to a realm where decentralized trading meets sophistication – welcome to Billi-Dex.',
  openGraph: {
    title: '🥞 Billidex - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'Billi-Dex, a revolutionary cryptocurrency DEX, beckons you into a world where seamless transactions meet cutting-edge technology. With its sleek interface and lightning-fast execution, Billi-Dex transforms swapping digital assets into a delightful experience, empowering users to navigate the crypto space with ease. Welcome to a realm where decentralized trading meets sophistication – welcome to Billi-Dex.',
    images: [{ url: 'https://www.billidex.finance/_next/image?url=%2Fimages%2FheroImage.png&w=1080&q=75' }],
  },
}

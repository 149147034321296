import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
   <>
             <img src="https://i.ibb.co/QnJRqNc/billicat.png" {...props} alt="" />

   </>
  );
};

export default Icon;

import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Ecosystem"),
    items: [
      {
        label: t("Trade"),
        href: "/swap",
      },
      {
        label: t("Earn"),
        href: "/farms",
      },
      {
        label: t("Billi-Pad"),
        href: "https://billipad.billidex.finance",
      },
      {
        label: t("NFT Marketplace"),
        href: "https://billisea.io",
      },
      {
        label: t("Billicatcoin"),
        href: "https://billicatcoin.org",
      },
      {
        label: t("Billi Academy"),
        href: "https://billiacademy.org/"
      }
    ],
  },
  {
    label: t("About"),
    items: [
      {
        label: t("Terms Of Service"),
        href: "https://billidex.finance/terms-of-service",
      },
      
    ],
  },
];

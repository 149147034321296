import { bscTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { FarmConfigV3, SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { CAKE_BNB_LP_MAINNET } from './common'

const v3TopFixedLps: FarmConfigV3[] = [
  {
    pid: 4,
    token0: bscTokens.wbnb,
    token1: bscTokens.busd,
    lpAddress: '0x85FAac652b707FDf6907EF726751087F9E0b6687',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 5,
    token0: bscTokens.usdt,
    token1: bscTokens.wbnb,
    lpAddress: '0x36696169C63e42cd08ce11f5deeBbCeBae652050',
    feeAmount: FeeAmount.LOW,
  },
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedLps,
  // new lps should follow after the top fixed lps
  // latest first
  {
    pid: 131,
    lpAddress: '0xE08078C1daACef415b2653e7256A70002F41Eeb6',
    token0: bscTokens.usdt,
    token1: bscTokens.huahua,
    feeAmount: FeeAmount.HIGH,
  },
  {
    pid: 130,
    lpAddress: '0xce44eBE42ABc496eACBd6582b6e14f3937116B23',
    token0: bscTokens.usdt,
    token1: bscTokens.irl,
    feeAmount: FeeAmount.HIGH,
  },
  {
    pid: 129,
    lpAddress: '0xf66A930Ed3b004BA16ee11b3A9B142EAf2259b0d',
    token0: bscTokens.wbnb,
    token1: bscTokens.bcat,
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 102,
    lpAddress: '0x1A1703Bf5f1Da9DB0f62d17e8c54e84Fd732D695',
    token0: bscTokens.bonk,
    token1: bscTokens.bnb,
    feeAmount: FeeAmount.HIGH,
  },
  {
    pid: 101,
    lpAddress: '0x2f74ad2c64be7DC07C9f51E9d338EcB7C1ee0B18',
    token0: bscTokens.usdt,
    token1: bscTokens.bonk,
    feeAmount: FeeAmount.HIGH,
  },
  {
    pid: 99,
    lpAddress: '0x4BBA1018b967e59220b22Ca03f68821A3276c9a6',
    token0: bscTokens.eth,
    token1: bscTokens.btcb,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 97,
    lpAddress: '0xBe141893E4c6AD9272e8C04BAB7E6a10604501a5',
    token0: bscTokens.eth,
    token1: bscTokens.usdt,
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 96,
    lpAddress: '0x8cCB4544b3030dACF3d4D71C658f04e8688e25b1',
    token0: bscTokens.twt,
    token1: bscTokens.bnb,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 95,
    lpAddress: '0x832EeBF89F99aACcf6640fe6b5E838066c630Fbe',
    token0: bscTokens.chess,
    token1: bscTokens.usdt,
    feeAmount: FeeAmount.HIGH,
  },
  {
    pid: 94,
    lpAddress: '0xcfe783e16c9a8C74F2be9BCEb2339769439061Bf',
    token0: bscTokens.usdt,
    token1: bscTokens.alpaca,
    feeAmount: FeeAmount.HIGH,
  },
  {
    pid: 93,
    lpAddress: '0xE4e695FA53598dA586F798A9844A3b03d86f421e',
    token0: bscTokens.btt,
    token1: bscTokens.usdt,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 91,
    lpAddress: '0xa98D8a5867D664B7A758652146fd93a7dE40eE82',
    token0: bscTokens.usdt,
    token1: bscTokens.trxv2,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 86,
    token0: bscTokens.ltc,
    token1: bscTokens.wbnb,
    lpAddress: '0xE3cBe4Dd1BD2F7101f17D586F44bAb944091D383',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 66,
    token0: bscTokens.doge,
    token1: bscTokens.wbnb,
    lpAddress: '0xce6160bB594fC055c943F59De92ceE30b8c6B32c',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 58,
    token0: bscTokens.wbnb,
    token1: bscTokens.uni,
    lpAddress: '0x647D99772863e09f47435782cbb6C96eC4A75f12',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 57,
    token0: bscTokens.eth,
    token1: bscTokens.usdt,
    lpAddress: '0x66E9AcBA7C3a82C8EE02fAF3E325Dd55D6581a8c',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 56,
    token0: bscTokens.usdt,
    token1: bscTokens.btcb,
    lpAddress: '0x6ee3eE9C3395BbD136B6076A70Cb6cFF241c0E24',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 55,
    token0: bscTokens.btcb,
    token1: bscTokens.wbnb,
    lpAddress: '0x6bbc40579ad1BBD243895cA0ACB086BB6300d636',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 54,
    token0: bscTokens.eth,
    token1: bscTokens.wbnb,
    lpAddress: '0xD0e226f674bBf064f54aB47F42473fF80DB98CBA',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 6,
    token0: bscTokens.btcb,
    token1: bscTokens.busd,
    lpAddress: '0x369482C78baD380a036cAB827fE677C1903d1523',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 7,
    token0: bscTokens.usdt,
    token1: bscTokens.btcb,
    lpAddress: '0x46Cf1cF8c69595804ba91dFdd8d6b960c9B0a7C4',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 8,
    token0: bscTokens.eth,
    token1: bscTokens.btcb,
    lpAddress: '0xD4dCA84E1808da3354924cD243c66828cf775470',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 9,
    token0: bscTokens.btcb,
    token1: bscTokens.wbnb,
    lpAddress: '0xFC75f4E78bf71eD5066dB9ca771D4CcB7C1264E0',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 10,
    token0: bscTokens.eth,
    token1: bscTokens.wbnb,
    lpAddress: '0x7d05c84581f0C41AD80ddf677A510360bae09a5A',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 11,
    token0: bscTokens.eth,
    token1: bscTokens.usdc,
    lpAddress: '0x539e0EBfffd39e54A0f7E5F8FEc40ade7933A664',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 12,
    token0: bscTokens.usdt,
    token1: bscTokens.usdc,
    lpAddress: '0x92b7807bF19b7DDdf89b706143896d05228f3121',
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 13,
    token0: bscTokens.usdc,
    token1: bscTokens.busd,
    lpAddress: '0x22536030B9cE783B6Ddfb9a39ac7F439f568E5e6',
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 14,
    token0: bscTokens.usdt,
    token1: bscTokens.busd,
    lpAddress: '0x4f3126d5DE26413AbDCF6948943FB9D0847d9818',
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 19,
    token0: bscTokens.dot,
    token1: bscTokens.wbnb,
    lpAddress: '0x62F0546cBcd684F7C394D8549119e072527C41Bc',
    feeAmount: FeeAmount.MEDIUM,
  },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'BCAT',
    lpAddress: '0x47a9B109Cfb8f89D16e8B34036150eE112572435',
    token: bscTokens.syrup,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 3,
    v1pid: 252,
    lpSymbol: 'BUSD-BNB LP',
    lpAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    token: bscTokens.busd,
    quoteToken: bscTokens.wbnb,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
